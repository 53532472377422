// Section Title
.section-title {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    text-transform: uppercase;
    font-size: 27px;
    line-height: initial;
    color: white;
    margin: 0 5px;

    &:nth-of-type(1) { font-family: "Mazzard Thin" }

    &:nth-of-type(2) { font-family: "Mazzard Bold" }
  }

  @media (min-width: 375px) {
    span { font-size: 32px }
  }

  @media (min-width: 600px) {
    span { font-size: 50px }
  }

  @media (min-width: $w_tablet) {
    span { font-size: 60px }
  }

  @media (min-width: $w_desktop) {
    span { font-size: 80px }
  }
}

.slider-arrow {
  width: 50px;
  height: 50px;
  background: $c_cyan;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  border-radius: 4px;
}

.header-slider {
  position: relative;

  #slider-item-1 .content{
    background-image: url(../assets/img/mob-header1.jpg);
  }

  #slider-item-2 .content{
    background-image: url(../assets/img/mob-header2.jpg);
  }

  #slider-item-3 .content{
    background-image: url(../assets/img/mob-header3.jpg);
  }

  #slider-item-4 .content{
    background-image: url(../assets/img/mob-header4.jpg);
  }

  .slick-dots {
    bottom: 20px;

    li button::before {
      color: $c_white;
      opacity: 0.35;
    }

    li.slick-active button::before { opacity: 0.75 }
  }

  .slider-arrow {
    bottom: calc(50% - 25px);
    display: none;

    &.left {
      left: 10px;
      img { transform: rotate(90deg) }
    }

    &.right {
      right: 10px;
      img { transform: rotate(-90deg) }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    font-family: Mazzard;
    font-style: normal;
    font-weight: 250;
    height: 305px;
    background-size: cover;

    .bg {
      background: rgba(70, 70, 70, 0.43);
      height: 100%;
      justify-content: center;
    }
  }

  @media (min-width: $w_tablet) {
    .slider-arrow { display: flex }
    .bg { padding: 0 100px }

    #slider-item-1 .content{
      background-image: url(../assets/img/header1.jpg);
    }

    #slider-item-2 .content{
      background-image: url(../assets/img/header2.jpg);
    }

    #slider-item-3 .content{
      background-image: url(../assets/img/header3.jpg);
    }

    #slider-item-4 .content{
      background-image: url(../assets/img/header4.jpg);
    }
  }
}

.section-description {
  padding: 10px;
  font-family: "Mazzard Thin";
  color: #fff;
  font-size: 16px;
  line-height: 15px;
  text-align: center;
  display: block;
  letter-spacing: -0.02em;
}

// ABOUT US
.about-us {
  background-color: $c_dark;

  p { color: $c_white }

  .content {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  p:nth-of-type(2) {
    font-size: 10px;
    text-align: right;
  }
}

// OUR SERVICES
.our-services {
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;

    img {
      width: 60%;
      box-shadow: 0 0 10px 1px silver;
    }

    .section-title {
      flex-direction: revert;
      margin-bottom: 50px;

      span {
        color: $c_cyan;

        &:nth-of-type(1) {font-family: "Mazzard Bold" }
        &:nth-of-type(2) { font-family: "Mazzard Thin" }
      }
    }
  }
}

// SPARE PARTS
.spare-parts {
  background-image: url(../assets/img/mob-motor.jpg);
  background-repeat: no-repeat;
  background-position-x: right;
  align-items: flex-end;
  background-size: cover;
  display: flex;
  height: 200px;

  .content {
    width: 70%;
    padding: 18px;
    justify-content: space-evenly;
  }

  .section-description {
    font-size: 12px;
    line-height: 13px;
  }

  .section-title {
    span{
      font-size: 20px;
      line-height: 97.7%;
    }
  }

  button {
    width: 50%;
  }

  @media (min-width: 522px) {
    background-image: url(../assets/img/motor.jpg);
    background-size: cover;
    height: 400px;

    .section-description  {
      font-size: 18px;
      line-height: 20px;
    }

    .content {
      .section-title {
        span {
          font-size: 40px;
        }
      }
    }
  }

  @media (min-width: 800px) {
    height: 500px;

    .content {
      .section-title {
        span {
          font-size: 80px;
        }
      }
    }

    button {
      text-transform: uppercase;
      background: #FF3E3E;
      color: #fff;
      width: 318px;
      height: 60px;
      font-size: 24px;
    }
  }

  @media (min-width: 1080px) {
    height: 600px;

    .content {
      width: 70%;
    }
  }

  @media (min-width: 1220px) {
    .content {
      width: 65%;
    }
  }

  @media (min-width: 1280px) {
    .content {
      width: 50%;
    }
  }
}

// SERVICES LIST
.services-list {
  background-image: var(--img-section5-mob);
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #010102;

  .services {
    .drop-down-list {
      header {
        .title {
          text-align: right;
        }
      }
    }
  }

  #accordion-services-right2 {
    @media (min-width: 1080px) {
      .menu {
        margin-top: -180px
      }
    }
  }

  @media (min-width: 1080px) {
    .services {
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      justify-content: space-between;

      @keyframes arrow {
        form { opacity: 0; }
        to { opacity: 1; }
      }

      .left, .right {
        width: 40%;

        .drop-down-list {
          .menu-list {
            &.animated:after {
              content: '';
              opacity: 0;
              width: 100px;
              animation: arrow;
              position: relative;
              position: relative;
              animation-delay: .3s;
              animation-duration: 1s;
              animation-fill-mode: forwards;
              border-bottom: 1px solid rgba(255, 255, 255, 0.568);
            }
          }
        }
      }

      .left {
        .drop-down-list {
          .menu {
            left: 40%;

            .menu-list {
              li {
                width: 90%;
                text-align: left;
              }
            }
          }

          header {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .title {
              text-align: end;
            }
          }

          &:nth-child(1) {
            .menu-list {
              &.animated:after {
                transform: rotate(82deg);
                top: -163px;
                left: -118px;
                width: 330px;
              }
            }
          }

          &:nth-child(2) {
            .menu { margin-top: -50px; }

            .menu-list {
              &.animated:after {
                transform: rotate(82deg);
                top: -138px;
                left: -139px;
                width: 280px;
              }
            }
          }
        }
      }

      .right {
        .drop-down-list {
          .menu { right: 40%; }

          header {
            width: 100%;
            display: flex;
            justify-content: flex-start;

            .title {
              text-align: start;
            }
          }

          &:nth-child(1) {
            .menu-list {
              &.animated:after {
                transform: rotate(-80deg);
                top: -100px;
                left: 117px;
                width: 200px;
              }
            }
          }

          &:nth-child(2) {
            .menu {
              margin-top: -100px;
            }

            .menu-list {
              &.animated:after {
                transform: rotate(-80deg);
                top: -110px;
                left: 132px;
                width: 225px;
              }
            }
          }

          &:nth-child(3) {
            .menu-list {
              &.animated:after {
                transform: rotate(-70deg);
                top: -48px;
                left: 67px;
              }
            }
          }
        }
      }

      .drop-down-list {
        padding: 80px 40px !important;
        flex-direction: row;

        header {
          order: 2;

          img {
            display: none;
          }
        }

        .menu {
          width: 260px;
          position: absolute;

          .menu-list {
            li {
              width: 100%;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.services-list .content { padding-bottom: 50px }

.services-list .content .section-title{ padding: 50px 0 40px 0 }

// SERVICE DEFECTING
.service-defecting {
  .content {
    align-items: center;
    justify-content: space-around;
    padding: 50px 0;

    .section-title {
      margin-bottom: 40px;

      span{ color: var(--main-color) }
    }
  }
}

.service-defecting {
  .content {
    .service-item {
      text-align: center;
      margin: 10px 0;

      p:nth-of-type(2) { font-size: 30px }

      @media (min-width: $w_tablet) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 30px;
        padding: 0 50px;

        p {
          display: flex;
          align-items: flex-end;
          border-bottom: 1px solid rgb(0, 0, 0);
        }

        p:nth-of-type(1) {
          text-align: start;
          font-size: 30px;
        }

        p:nth-of-type(2) {
          width: 100%;
          justify-content: flex-end;
        }
      }

      @media (min-width: $w_desktop) {
        padding: 0 170px;
      }
    }
  }
}

// WORK EXAMPLES
.work-examples {
  background-color: $c_dark;

  .section-title { padding-top: 50px }

  .slider-content {
    display: flex !important;
    width: 100%;
    flex-direction: row;
    padding: 50px 0;
    justify-content: space-between;

    .img{
      width: calc(50% - 5px);
      height: 50%;
      object-fit: contain;
    }

    @media (max-width: $w_tablet) and (orientation: portrait) {
      flex-direction: column;

      .img {
        width: calc(100% - 20px);
        margin: 5px 10px;
      }
    }

    @media (min-width: $w_desktop) {
      width: 1100px !important;
      margin: 0 auto;
      padding: 80px 0;

      .img { margin: 0 10px }
    }
  }
}

.work-examples-slider {
  position: relative;

  .slider-arrow {
    bottom: calc(50% - 25px);

    &.left {
      left: 10px;
      img { transform: rotate(90deg) }
    }

    &.right {
      right: 10px;
      img { transform: rotate(-90deg) }
    }

    @media (min-width: 800px) {
      &.left { left: 60px }
      &.right { right: 60px }
    }

    @media (min-width: 1200px) {
      &.left { left: calc((100% - 1050px) / 2) }
      &.right { right: calc((100% - 1050px) / 2) }
    }
  }
}

// REVIEW
.reviews {
  .review-slider-sections {
    &:focus {
      outline: none;
    }

    margin: 0 auto;
    padding: 0 18px;

    @media (min-width: 710px) {
      padding: 0 50px;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;;

  .section-title span {
    color: #03131F;
  }

  .divider {
    display: none;
    margin: 0 5em;
  }

  @media (min-width: 800px) {
    padding: 70px 0;

    .section-title {
      padding: 0 0 70px 0;
    }

    .slick-dots {
      bottom: -40px;

      li {
        margin: 0 10px;

        &.slick-active button:before {
          font-size: 25px;
          width: 25px;
          height: 25px;
          color: #FF3E3E;
        }

        button:before {
          font-size: 20px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    .review-slider {
      .review-slider-sections {
        .left, .right {
          width: 100%;
        }

        display: flex !important;
        flex-direction: row !important;
        padding: 0;
        max-width: 1100px !important;

        .divider {
          display: block;
        }
      }
    }
  }
}

.reviews .section-title,
.our-partners .content .section-title {
  display: inline;
  text-align: center;
  padding: 40px 0;
}

.review-message {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.review-message .author {
  align-self: flex-end;
  font-family: "Mazzard Bold";
}

// OUR PARTNERS
.our-partners {
  background-color: #010102;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  .partners-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 60px;

    img {
      max-width: 30%;
      height: auto;
    }
  }
}

// CONTACTS
.contacts {
  .content {
    padding: 20px;

    .section-title {
      padding: 40px 0;
      display: inline;

      span { color: $c_cyan }
    }
  }
}

.contacts-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  .contact {
    display: flex;
    width: 100%;
    font-size: 20px;

    .number {
      padding: 12px 0;

      a {
        text-decoration: none;
        color: rgb(26, 26, 26);
      }
    }

    .name {
      padding: 12px;
      padding-left: 20px;
    }
  }
}

footer p {
  height: 40px;
  background-color: #153F65;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}


.application-form {
  width: calc(100vw - 60px);
  min-width: 270px;
  max-width: 400px;
  justify-content: space-between;
  flex: 1 0 auto;

  .section-title {
    align-items: self-start;

    span {
      color: $c_black;
      font-size: 34px;
    }
  }

  .label {
    font-size: 16px;
    color: $c_black;
    text-transform: uppercase;
  }

  .button {
    text-transform: uppercase;
    width: 100%;
  }

  .field-wrapper {
    &:not(.column) {
      align-items: center;
      justify-content: space-between;

      input {
        width: calc(100% - 100px);
      }
    }

    &.inline {
      align-items: flex-end;
      input {
        border-width: 0 0 1px 0;
        border-radius: 0;
        height: 20px;
        margin-left: 10px;
        width: 100%;
      }
    }
  }

  @media (min-width: $w_tablet) {
    .section-title span { font-size: 48px }
  }

  .checkbox-container {
    justify-content: space-between;
    margin-top: 50px;
  }
}
