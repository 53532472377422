.navigation-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $c_dark;
  height: 70px;
  padding: 20px;

  .logo { height: 35px }

  .lang-switcher {
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;

    b { margin: 0 5px }
    > * { color: $c_white }
    .enable { color: $c_silver }
  }
}
