.modal-wrapper {
  display: none;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  justify-content: center;
  align-items: center;

  &.active { display: flex }

  .modal {
    background: $c_white;
    border-radius: 3px;
    padding: 20px;
    box-shadow: 0px 0px 15px $c_black;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 20px);

    .spinner-wrapper {
      position: absolute;
      width: calc(100% - 40px);
      height: calc(100% - 80px);
      display: flex;
      align-items: center;
      justify-content: center;
      top: 40px;
    }

    img {
      position: absolute;
      right: 20px;
      top: 20px;
      height: 20px;
    }
  }
}
