@keyframes drop {
  from {
    top: 0px;
    opacity: 0;
  }

  75% {
    top: 0;
    animation-timing-function: ease-in;
    opacity: 1;
  }

  to {
    top: 0px;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

@keyframes out {
  from {
    top: 0px;
    opacity: 1;
  }

  75% {
    top: -50px;
    animation-timing-function: ease-out;
    opacity: 0;
  }

  to {
    top: 0px;
    animation-timing-function: ease-out;
    opacity: 0;
  }
}

.drop-down-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .open {
    display: flex;
    justify-content: space-between;
    transition: 1s;
  }

  .title {
    font-size: 30px;
    color: #fff;
  }

  .menu {
    height: 0;

    .animated {
      li{
        position: relative;
        animation: drop 0.5s;
        animation-fill-mode: forwards;
      }
    }

    ul{
      font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .menu-list {
      height: 0;
      margin-bottom: 40px;
      transition: 0.5s;

      li {
        opacity: 0;
        top: -160px;
        font-size: 20px;
        color: #fff;
        padding: 10px 0;
        font-family: "Mazzard Thin";
        text-align: end;
      }
    }
  }

  &.active {
    .open {
      img {
        transform: rotate(180deg);
      }
    }

    .menu {
      height: 240px;
      transition: .5s;

      .menu-list {
        display: flex;
        height: 250px;
        margin-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.568);
        flex: 1 0 auto;
      }
    }
  }
}
