.flex,
.row,
.column { display: flex }

.column { flex-direction: column }

// TODO: Refactor it
.flex-100 {
  width: 100%;
  display: flex;
}

.layout-align-right {
  justify-content: right;
}

.layout-align-center {
  justify-content: center;
}

.layout-align-start-center {
  align-items: center;
}

.layout-align-center-center {
  justify-content: center;
  align-items: center;
}

.h-100 {
  height: 100%;
}
