@import './_reset.scss';
@import './_flex.scss';
@import './_variables';
@import './_spinner';
@import './_modal';
@import './page-main.scss';
@import './drop-down-list.scss';
@import './page-main-adaptive.scss';
@import './drop-down-list.scss';
@import './_navigation-bar.scss';

@font-face {
  font-family: "Mazzard";
  src: url("../assets/fonts/MazzardL-Regular.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Mazzard Thin";
  src: url("../assets/fonts/MazzardL-Thin.otf");
}

@font-face {
  font-family: "Mazzard Bold";
  src: url("../assets/fonts/MazzardM-Bold.otf");
  font-weight: bold;
}

* {
  font-family: "Mazzard", sans-serif;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.3em;
}

.preloader {
  background-color: $c_cyan;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;

  img {
    width: 50vw;
    height: auto;
    margin-bottom: 40px;
  }
}

strong { font-weight: 700 }
b { font-weight: bold }

.pointer { cursor: pointer }
.no-outline { outline: none }
.desktop { display: none }

@media (min-width: $w_desktop) {
  .desktop { display: flex }
}

.h1 { font-size: 36px }
.h2 { font-size: 24px }

.button {
  height: 36px;
  width: 150px;
  font-size: 18px;
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: $c_black;
  background: $c_white;
  text-decoration: none;
  justify-content: center;
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid $c_black;

  &.secondary {
    color: $c_white;
    background: $c_black;
  }
}

input,
select,
textarea {
  height: 40px;
  width: 100%;
  padding-left: 10px;
  border: 1px solid $c_black;
  color: $c_black;
  font-size: 16px;
  border-radius: 3px;
  outline: 0;
  box-shadow: none;
}

textarea {
  height: 100px;
  resize: none;
}

.error {
  color: #f00;
  font-size: 14px;
}

.padding-none { padding: 0 }

.m-t-10 { margin-top: 10px }
.m-t-20 { margin-top: 20px }

.checkbox-wrapper {
  align-items: center;
  flex-direction: column;

  @media (min-width: 350px) {
    flex-direction: row;

    .label { margin-right: 5px }
  }

  .checkbox {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid #000000;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  
    &.active {
      background-color: $c_cyan;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
  }
}
