$c_white: #fff;
$c_black: #000;
$c_dark: #0f0f0f;
$c_gray: #2f2f2f;
$c_silver: #838383;
$c_yellow: #F0DC20;
$c_cyan: #03131F;

$w_desktop: '1200px';
$w_tablet: '700px';
