@media (min-width: 522px) {
  .header-slider {
    .slider-item {
      .content {
        height: calc(100vh - 70px);
      }
    }
  }
}

@media (min-width: 800px) {
  .about-us {
    .content {
      .section-title { margin: 40px 0 }
      .section-description { padding: 40px }

      p:nth-of-type(2) {
        font-size: 20px;
        line-height: 21px;
        align-self: flex-end;
        width: 50%;
      }
    }
  }

  .section-title {
    span {
      font-size: 80px;
      line-height: initial;
    }
  }

  .our-partners {
    .content {
      padding: 70px 0;
      background: $c_dark;

      .section-title {
        padding: 0 0 70px 0;
      }
    }
  }

  .contacts {
    .content {
      padding: 70px 170px;

      .contacts-list {
        .contact {
          font-size: 30px;
        }
      }
    }
  }

  .services-list {
    .content {
      .drop-down-list {
        padding: 20px 170px;
      }
    }
  }
}

@media (min-width: 890px) {
  .section-description {
    padding: 80px;
    font-size: 26px;
    line-height: 26px;
  }
}

@media (min-width: 1180px) {
  .services-list {
    .content {
      .section-title {
        padding: 70px 0;
      }
    }
  }

  .service-defecting {
    .content {
      padding: 70px 0;
    }
  }
}
